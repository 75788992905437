import React from 'react'
import Layout from '../components/layout'

import styles from './404.module.scss'

const NotFoundPage = () => (
  <Layout>
    <article className={styles.fourohfour}>
      <h1>NOT FOUND</h1>
      <p>The page you are trying to view doesn't exist.  Please use the links above to navigate the site</p>
    </article>
  </Layout>
)

export default NotFoundPage
